
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






















































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.tv-solutions,
[class*='tv-solutions--'] {
  overflow: hidden;
}

.tv-solutions__hero {
  ::v-deep {
    .hero__slides {
      @include mq($until: l) {
        height: auto;
      }
    }

    .hero__slides__item--product {
      @include mq($until: l) {
        position: static;
      }
    }

    .hero__slides__item__content {
      @include mq($until: l) {
        padding: $spacing * 3 $spacing;
      }
    }
  }
}

.tv-solutions__section-hero__btn {
  & + & {
    margin-left: $spacing * 2;
  }
}

.tv-solutions__hero__links {
  display: flex;

  ::v-deep {
    a {
      flex-shrink: 0;
    }

    a:first-child {
      margin-right: $spacing * 5.5;
    }
  }
}

.tv-solutions__section-hero {
  margin-top: 0;

  ::v-deep {
    .section-hero__content__title {
      @include mq(m) {
        font-size: 3rem;
      }

      @include mq(l) {
        font-size: 4.8rem;
      }
    }

    .section-hero__content__text {
      @include mq(m) {
        font-size: 1.8rem;
      }
    }

    .section-hero__content {
      @include mq(xl) {
        width: col(6);
      }
    }
  }
}

.tv-solutions__offer {
  position: relative;
  align-items: center;

  @include mq(m) {
    flex-direction: row-reverse !important;
  }

  @include mq(l) {
    background-position: -10% 0;
  }
}

.tv-solutions__offer__speedlines {
  top: 0;
  width: 80%;
  height: 30rem;

  @include mq(m) {
    left: 0;
    width: 25%;
    height: 25%;
    transform: translateX(-50%);
  }
}

.tv-solutions__offer__covers {
  margin-bottom: $spacing * 2;

  @include mq($until: m) {
    flex-shrink: 0;
  }

  @include mq(l) {
    margin-bottom: 0;
  }
}

.tv-solutions__offer__checklist {
  flex-shrink: 0;

  @include mq(m) {
    margin-right: $spacing;
  }
}

.tv-solutions__smart {
  position: relative;
  overflow: hidden;

  @include mq(xxl) {
    padding-top: $spacing * 9.5 !important;
    padding-bottom: $spacing * 9.5 !important;
  }
}

.tv-solutions__smart__picture {
  @include mq(s) {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ::v-deep img {
      width: 100%;
    }
  }
}

.tv-solutions__smart__title,
.tv-solutions__smart__text {
  @include mq(s) {
    width: 50%;
    color: $white;
  }

  @include mq(xl) {
    width: col(5);
  }
}

.tv-solutions__smart__title {
  margin: 0;
  margin-bottom: $spacing * 1.5;
}

.tv-solutions__solutions {
  @include bg-color;

  background-image: url('/assets/images/speedlines-card-bg.svg');
  background-position: 95% 20px;
  background-repeat: no-repeat;
  background-size: 25%;

  @include mq(m) {
    margin: 0 -#{$spacing};
    background-size: 15%;
  }

  @include mq(xl) {
    margin: 0 auto;
  }
}

.tv-solutions__solutions__checklist {
  @include mq($until: m) {
    &:first-child {
      margin-bottom: $spacing * 3;
    }
  }

  @include mq(m) {
    margin: 0 $spacing;
  }

  @include mq(xl) {
    max-width: col(5);
    margin: 0;

    &:first-child {
      margin-right: col(1);
    }
  }
}

.tv-solutions__solutions__checklist__label {
  margin-bottom: $spacing;

  .icon {
    width: auto;
    height: 4.5rem;
    fill: $c-pink-medium;
  }

  ::v-deep span {
    @extend %fw-bold;

    display: inline-block;
    margin-left: -#{$spacing * 1.5};
    padding: 2px 10px;
    color: $white;
    font-family: $ff-alt;
    font-size: 1.2rem;
    background-color: $c-pink-medium;
    border-radius: 15px;
  }
}

.tv-solutions__solutions__checklist__title {
  margin: 0;
  margin-bottom: $spacing * 1.5;

  @include mq(xl) {
    margin-bottom: $spacing * 3;
  }
}

.tv-solutions__hero__show-offers {
  @extend %fw-bold;

  display: flex;
  align-items: center;
  margin-top: $spacing;
  text-decoration: none;

  @include mq(m) {
    margin-top: $spacing * 1.5;
  }
}

.tv-solutions__hero__show-offers__icon {
  width: 3rem;
  height: 4rem;
  margin-right: $spacing;

  @include mq(m) {
    width: 4rem;
    height: 5rem;
  }
}

.tv-solutions__hero__show-offers__label {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.2;

  &::after,
  &::before {
    bottom: -5px;
  }

  @include mq(m) {
    font-size: 1.8rem;
    line-height: 1.3;
  }
}
