
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

































.check-list,
[class*='check-list--'] {
  margin: 0;
  padding: 0;
}

.check-list__item {
  display: flex;
  list-style-type: none;

  &:not(:last-child) {
    margin-bottom: $spacing;
  }

  @include mq(l) {
    &:not(:last-child) {
      margin-bottom: $spacing * 2;
    }
  }
}

.check-list__item__icon {
  margin-right: $spacing;
  fill: $c-pink-medium;
}
